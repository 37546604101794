<template>
  <div v-if="showModal" class="modal">
    <div class="modal-content">
      <div class="modal-header">
        <span>Imprimir {{ type === 'bracelet' ? 'pulseira' : 'etiqueta' }}</span>
        <Close class="modal-close" @click="closeModal()" />
      </div>
      <div class="modal-body">
        <div class="printer-select-group">
          <span>Impressora</span>
          <select class="form-control" v-model="selectedPrinter">
            <option value="" selected disabled>Selecione uma impressora</option>
            <option v-for="printer in availablePrinters" :key="printer.uid" :value="printer.uid">
              {{ printer.name }}
            </option>
          </select>
        </div>
        <hr>
        <div class="printing-settings">
          <div class="settings-group">
            <span>Tamanho</span>
            <select class="form-control" v-model="size">
              <option value="child">Criança</option>
              <option value="adult">Adulto</option>
            </select>
          </div>
          <div class="template-settings-group">
            <span>Layout</span>
            <div class="template-settings-group__item">
              <input type="radio" id="layout1" name="braceletLayout" value="layout1" checked v-model="labelLayout">
              <div class="template-item-wrapper">
                <img v-if="printSettings.code_type === 'bar'" src="@/assets/images/template1-bar.png" alt="Layout 1 de pulseira" />
                <img v-if="printSettings.code_type === 'qr'" src="@/assets/images/template1-qr.png" alt="Layout 1 de pulseira" />
                <img v-if="printSettings.code_type === 'none'" src="@/assets/images/template1-none.png" alt="Layout 1 de pulseira" />
              </div>
            </div>
            <div v-if="printSettings.code_type === 'bar'" class="template-settings-group__item">
              <input type="radio" id="layout2" name="braceletLayout" value="layout2" v-model="labelLayout">
              <div class="template-item-wrapper">
                <img v-if="printSettings.code_type === 'bar'" src="@/assets/images/template2-bar.png" alt="Layout 2 de pulseira" />
              </div>
            </div>
          </div>
          <hr>
          <div class="info-settings-group">
            <span class="info-settings-group__title">
              Informações do paciente
              <InfoCircle 
                class="cursor-pointer"
                v-b-tooltip.hover.top="'Para alterar as informações do paciente a serem impressas na pulseira, acesse a aba Impressão nas Configurações da Clínica.'"
              />
            </span>
            <div class="info-settings-group__wrapper">
              <div class="info-item" v-for="(field, index) in braceletFields" :key="field">
                <span>{{ field }}</span>
                <span v-if="index !== braceletFields.length - 1"> - </span>
              </div>
            </div>
          </div>
        </div>
        <hr>
        <div class="buttons-wrapper">
          <button class="btn btn-outline-primary back-button" @click="closeModal">Voltar</button>
          <button class="btn btn-primary print-bracelet-button" @click="sendSettingsPrint">Imprimir {{ type === 'bracelet' ? 'pulseira' : 'etiqueta' }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { generateBracelet } from './BraceletGeneratorHelper.js';
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
  name: 'BrowserPrintModal',
  props: {
    printSettings: {
      type: Object,
      required: true
    },
    patientIdInfo: {
      type: Object || null,
    },
    patientId: {
      type: String || null,
    },
    type: {
      type: String || undefined,
      required: true
    }
  },
  components: {
    Close: () => import('@/assets/icons/close.svg'),
    InfoCircle: () => import('@/assets/icons/info-circle.svg')
  },
  data() {
    return {
      showModal: true,
      selectedPrinter: '',
      availablePrinters: [],
      browserPrint: window.BrowserPrint || null,
      size: 'child',
      labelLayout: 'layout1',
      patientInfo: {},
      clinicId: getCurrentClinic().id,
      braceletFields: []
    }
  },
  mounted() {
    if (!this.browserPrint) {
      this.$toast.error('O software Zebra Browser Print não está em execução. Por favor, inicie o software e tente novamente.');
      this.showModal = false;
      return;
    }
    this.getAvailablePrinters();
    this.getDefaultPrinter();
    this.getPatientInfo();
    this.parseBraceletFields();
  },
  methods: {
    getAvailablePrinters() {
      this.availablePrinters = [];
      this.browserPrint.getLocalDevices((devicesList) => {
        if (devicesList.length) {
          this.availablePrinters = devicesList;
        }
      }, () => {
        this.$toast.error('Erro ao buscar impressoras disponíveis.');
      }, "printer");
    },
    getDefaultPrinter() {
      this.browserPrint.getDefaultDevice("printer", (device) => {
        this.selectedPrinter = device.uid
      }, () => {
        this.$toast.error('Erro ao buscar impressora padrão.');
      }, "printer");
    },
    sendSettingsPrint() {
      const selected = this.availablePrinters.find(printer => printer.uid === this.selectedPrinter);
      if(!selected) {
        this.$toast.warning('Selecione uma impressora!');
        return;
      }

      const zpl = generateBracelet(this.patientInfo, this.printSettings, this.labelLayout, this.size);

      if(zpl){
        selected.send(zpl, () => {
          this.$toast.success('Impressão realizada com sucesso!');
        }, (error) => {
          this.$toast.error('Erro ao imprimir', error);
        })
      }
    },
    getPatientInfo() {
      if(!this.patientId && Object.keys(this.patientIdInfo).length){
        this.patientInfo = this.patientIdInfo;
        return;
      }
      this.api.showPatientDashboard(this.clinicId, this.patientId).then(({ data }) => {
        this.patientInfo = data.patient;
      }).catch(error => {
        console.error('Erro ao buscar informações do paciente: ', error);
      })
    },
    closeModal(){
      this.showModal = false;
      this.$emit('closeBrowserPrintModal');
    },
    parseBraceletFields(){
      if(!Object.keys(this.printSettings).length) return;
      Object.entries(this.printSettings).forEach(entry => {
        if(entry[1]){
          if(entry[0] === 'name') this.braceletFields.push('Nome');
          if(entry[0] === 'cpf') this.braceletFields.push('CPF');
          if(entry[0] === 'rg') this.braceletFields.push('RG');
          if(entry[0] === 'birth_date') this.braceletFields.push('Data nasc.');
          if(entry[0] === 'age') this.braceletFields.push('Idade');
          if(entry[0] === 'gender') this.braceletFields.push('Sexo');
          if(entry[0] === 'medical_record') this.braceletFields.push('Nº prontuário');
          if(entry[0] === 'mother_name') this.braceletFields.push('Nome da mãe');
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  font-family: "Nunito Sans", serif;


}

.modal-background {
  position: absolute;
  width: 100%;
  height: 100%;
}

.modal-content {
  position: relative;
  z-index: 10000;
  background-color: white;
  border-radius: 8px;
  max-width: 800px;
  max-height: 80%;
  overflow: auto;

  .printer-select-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    width: 100%;

    span {
      font-weight: bold;
    }
  }

  .printing-settings {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;

    &__title {
      font-size: 18px;
      font-weight: 600;
      text-align: center;
    }

    .settings-group {
      display: flex;
      flex-direction: column;
      gap: 10px;
      white-space: nowrap;
      align-self: flex-start;
      width: 100%;

      span {
        font-weight: bold;
      }
    }

    .template-settings-group {
      display: flex;
      flex-direction: column;
      gap: 10px;
      white-space: nowrap;
      align-self: flex-start;
      width: 100%;
      margin: 10px 0;

      span {
        font-weight: bold;
      }

      &__item {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: center;

        input {
          cursor: pointer;
          transform: scale(1.3);
        };

        .template-item-wrapper {
          width: 100%;
          border: 1px solid lightgray;
          border-radius: 8px;
          text-align: center;
        }
      }
    }

    .info-settings-group {
      display: flex;
      flex-direction: column;
      gap: 10px;
      white-space: nowrap;
      align-self: flex-start;
      width: 100%;

      &__title {
        font-weight: bold;

        
        svg {
          width: 18px;
          margin-bottom: 3px;
          cursor: help;
        }
      }

      &__wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: flex-start;
      
        .info-item {
          font-size: small;
          font-weight: 600;
          display: flex;
          gap: 10px;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .modal-header {
    font-size: 18px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid #ddd;
  }

  .modal-body {
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;

    img {
      max-width: 100%;
      max-height: 100%;
    }

    .buttons-wrapper{
      align-self: flex-end;

      .print-bracelet-button {
        font-weight: 600;
        align-self: flex-end;
        box-shadow: 0 3px 1px -2px #00000033, 0 2px 2px 0 #00000024, 0 1px 5px 0px #0000001F;
      }

      .back-button {
        font-weight: 600;
        border: none;
      }
    }
  }

  .file-id {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 8px;

    span {
      font-size: 18px;
      font-weight: 600;
    }

    .open-new-tab {
      font-size: 16px;
      font-weight: 700;
      color: var(--primary);
      cursor: pointer;
    }
  }

  .modal-close {
    cursor: pointer;
    width: 20px;
    height: 20px;

    svg {
      stroke: lightgray;
    }
  }

  hr {
    border: none;
    border-top: 1px solid rgba(lightgray, 0.8);
    color: #333;
    overflow: visible;
    text-align: center;
    height: 5px;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 10px;
  }
}
</style>