import moment from 'moment';

export const generateBracelet = (patientInfo, printSettings, layout, braceletSize) => {
    // RATIO MM / POINTS - 12.5
    let marginTop = 0;

    if(braceletSize === 'adult'){
        marginTop = 1200;
    }

    if(layout === 'layout2' || printSettings.code_type === 'none') marginTop += 400;

    let gender = 'não informado';
    if(patientInfo.gender === 'F'){
      gender = 'Feminino'
    } else if(patientInfo.gender === 'M'){
      gender = 'Masculino'
    }

    let birthday = 'não informado';
    let age = 0;
    if(patientInfo.birthday){
        age = moment().diff(patientInfo.birthday, 'years');
        birthday = moment(patientInfo.birthday).format('DD/MM/YYYY');
    }

    // Geração do código de barras ou QR Code
    let printableCode = '';
    if(printSettings.code_type === 'bar' && printSettings.medical_record){
        if(layout === 'layout1'){
            printableCode = `^FO5, ${1120 + marginTop}
^BY2,2,90
^B3N,N,100,Y,N
^FD${patientInfo.medical_record_number}^FS`;
        } else {
            printableCode = `^FO5, ${marginTop + 200}
^BY4,20,1000
^B3R,N,40,N,N
^FD${patientInfo.medical_record_number}^FS`;
        }
    } else if(printSettings.code_type === 'qr' && printSettings.medical_record){
        printableCode = `^FO40, ${1050 + marginTop} ^BQN, 2, 10 ^FDQA, ${patientInfo.medical_record_number} ^FS`
    } else {
        if(layout !== 'layout2' && printSettings.code_type !== 'none') marginTop += 400;
    }

    // Linha do nome (sempre obrigatório)
    const nameRow = `^FO70,${marginTop}
^A0B,35,35
^FB1000,1,1,L,0
^FH^FD
${patientInfo.name}
^FS`;

    let row1 = '';
    let row2 = '';
    let row3 = '';

    // Posição da primeira linha das informações do paciente em relação a borda superior da pulseira
    let rowPosition = 120;

    // Renderizar primeira linha: CPF e RG
    if(printSettings.cpf || printSettings.rg){
        row1+= `^FO${rowPosition},${marginTop}
^A0B,25,25
^FB1000,1,1,L,0
^FH^FD
${printSettings.cpf ? `CPF: ${patientInfo.cpf || 'não informado'}               ` : ''}
${printSettings.rg ? `RG: ${patientInfo.rg || 'não informado'}` : ''}
^FS`;
        rowPosition += 40;
    }

    // Renderizar segunda linha: Data de nascimento, idade, sexo e número do prontuário
    if(printSettings.birth_date || printSettings.age || printSettings.medical_record){
        row2 += `^FO${rowPosition},${marginTop}
^A0B,25,25
^FB1000,1,1,L,0
^FH^FD
${printSettings.birth_date ? `D. Nasc: ${birthday} ${(printSettings.age && birthday !== 'não informado') ? `(${age > 1 ? `${age} anos` : `${age} ano`})` : ''}               ` : ''}
${!printSettings.birth_date && printSettings.age ? `Idade: ${age > 1 ? `${age} anos` : `${age} ano`}            ` : ''}
${printSettings.gender ? `Sexo: ${gender}            ` : ''}
${printSettings.medical_record ? `Nº Prontuário: ${patientInfo.medical_record_number || ''}` : ''}
^FS`;
        rowPosition += 40;
    }

    // Renderizar terceira linha: Nome da mãe
    if(printSettings.mother_name){
        row3 += `^FO${rowPosition},${marginTop}
^A0B,25,25
^FB1000,1,1,L,0
^FH^FD
Mãe: ${patientInfo.mother_name || 'não informado'}
^FS`;
    }

return `^XA ^CI28

${printableCode}

${nameRow}

${row1}

${row2}

${row3}

^PQ
^XZ`;
}